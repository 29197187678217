<template>
  <loading v-if="processing" />
  <div class="modal">
    <div class="header"><h2>Edit Project</h2></div>
    <div class="modal-content">
      <form class="createProject">
        <div class="form-header">
          <h5>PROJECT INFORMATION</h5>
        </div>
        <div class="inputs">
          <div class="input">
            <label>Project Name</label>
            <input
              type="text"
              placeholder="Project Name"
              v-model="projectName"
            />
          </div>
          <div class="input">
            <label>Production Company</label>
            <input
              type="text"
              placeholder="Production Company"
              v-model="prodCompany"
            />
          </div>
          <div class="form-header">
            <h5>SYSTEM</h5>
          </div>
          <div class="input">
            <label>Enable Streaming</label>
            <input
              class="checkbox"
              v-model="enableStreaming"
              type="checkbox"
              checked="true"
            />
          </div>

          <div v-show="error" class="error">{{ this.errorMsg }}</div>
        </div>
        <button @click.prevent="updateProject">Update Project</button>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../firebase/firebaseInit.js";
import loading from "../Loading.vue";

export default {
  name: "Register",
  props: ["projectId"],
  emits: ["close-modal"],
  data() {
    return {
      projectName: "",
      prodCompany: "",
      prodCompanyUid: "",
      //enableStreaming is not implimented
      enableStreaming: false,
      error: "",
      errorMsg: "",
      processing: false,
    };
  },
  components: {
    loading,
  },
  created() {},
  mounted() {
    const docRef = db.collection("projects").doc(this.projectId);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Document data:", doc.data().firstName);
          this.projectName = doc.data().projectName;
          this.prodCompany = doc.data().prodCompany;
          this.enableStreaming = doc.data().enableStreaming;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },

    async updateProject() {
      if (this.$store.state.userClaims.admin || this.$store.state.userClaims.broadcaster) {
      if (this.projectName !== "" && this.prodCompany !== "") {
        this.error = false;
        this.errorMsg = "";
        const projectDatabase = db.collection("projects");
        await projectDatabase
          .doc(this.projectId)
          .update({
            projectName: this.projectName,
            prodCompany: this.prodCompany,
            enableStreaming: this.enableStreaming,
          })
          .then(() => {
            console.log("Document successfully updated!");
            this.closeModal();
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          })
          return;
      }
      this.error = true;
      this.errorMsg = "Please fill out all the fields!";
      return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
  flex-grow: 3;
  max-width: 360px;
  background-color: #2f323c;
  height: 600px;
  margin-left: 10px;

  .header {
    background-color: #383c49;
    height: 70px;
    display: flex;
    align-items: center;
    color: #e5ecff;
    font-size: 1.5em;
    font-weight: 700;
    padding-left: 25px;

    p {
      text-align: center;
    }

    button {
      align-self: center;
    }
  }
}
.modal-content {
display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    padding: 15px 15px;
    height: 100%;
    background-color: #2f323c;

  .createProject {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  label {
    font-size: 12px;
    color: RGB(239, 243, 255);
  }

  input {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    margin: 10px 0px 15px 0px;
    color: #eff3ff;
    padding: 10px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .checkbox {
      height: auto;
      margin: 0px 10px 0px 39px;
      margin-right: auto;
    }
  }

  button {
    background-color: RGB(0, 132, 255);
    border: none;
    height: 37px;
    width: 100%;
    color: #eff3ff;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-top: auto;
  }

  button:hover {
    background-color: #0084ff;
  }
}

.form-wrap {
  //padding: 15px 15px;
}

.form-header {
  border-bottom: 1px solid RGB(123, 138, 173);
  margin-bottom: 15px;
  display: flex !important;
}

.emails.emails-input {
  max-height: inherit;
  border-radius: 0.25rem;
  background: #fff;
  border: 1px solid #c3c2cf;
  box-sizing: border-box;
  padding: 0.375rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  cursor: text;
  overflow: auto;
}

.emails.emails-input .email-chip {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  background: rgba(102, 153, 255, 0.2);
  vertical-align: top;
  border-radius: 6.25rem;
  padding-left: 0.625rem;
  padding-right: 1.5rem;
  margin: 0.125rem;
  max-width: 100%;
  overflow: hidden;
}

.emails.emails-input .email-chip .content {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emails.emails-input .email-chip .remove {
  text-decoration: none;
  color: inherit;
  text-align: center;
  position: absolute;
  cursor: pointer;
  width: 1rem;
  font-size: 1rem;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.emails.emails-input .email-chip.invalid {
  background: #fff;
  border-bottom: 1px dashed #d92929;
  border-radius: 0;
  padding-left: 0;
  padding-right: 1rem;
}

.emails.emails-input input {
  border: 0;
  line-height: inherit;
  font-size: inherit;
  color: inherit;
  margin: 0.125rem;
}

.emails.emails-input input::placeholder,
.emails.emails-input input::-ms-input-placeholder,
.emails.emails-input input:-ms-input-placeholder {
  color: #c3c2cf;
  opacity: 1;
}

.emails.emails-input input:focus {
  outline: none;
}
</style>