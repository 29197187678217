<template>

  <div class="projectManagement">

    <div class="header">
      <h2>Project</h2>
      <div class="actions">
        <button
          class="button"
          id="deleteProject"
          @click.prevent="selectAction($event)"
          :class="{
            active:
              modalMode == 'deleteProjectModal' && UserModalActive == true,
          }"
        >
          Delete
        </button>
        <button
          class="button"
          id="editProject"
          @click.prevent="selectAction($event)"
          :class="{
            active: modalMode == 'editProjectModal' && UserModalActive == true,
          }"
        >
          Edit
        </button>
        <button
          class="button"
          id="createProject"
          @click.prevent="selectAction($event)"
          :class="{
            active:
              modalMode == 'createProjectModal' && UserModalActive == true,
          }"
        >
          Add
        </button>
        <button class="button" @click.prevent="getAllProjects()">
          <font-awesome-icon :icon="['fas', 'redo-alt']" />
        </button>
      </div>
    </div>
    <div class="modalBody">
      <div class="datawrapper">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark datatable"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :rowSelection="rowSelection"
          :defaultColDef="defaultColDef"
          @grid-ready="onGridReady"
          @grid-size-changed="sizeToFit"
          @selection-changed="onSelectionChanged"
          :sortingOrder="sortingOrder"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
        <component
    :projectId="uid"
    :is="modalMode"
    v-if="UserModalActive"
    v-on:close-modal="closeUserModal"
  />
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import createProjectModal from "./CreateProjectModal.vue";
import editProjectModal from "./EditProjectModal.vue";
import deleteProjectModal from "./DeleteProjectModal.vue";
// import inviteCreateUserModal from "./inviteCreateUserModal.vue";
// import editUserModal from "./EditUserModal.vue";
//import firebase from "firebase/compat/app";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import "firebase/compat/auth";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../firebase/firebaseInit.js";

library.add(fas);

export default {
  data() {
    return {
      columnDefs: null,
      rowData: null,
      rowSelection: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: { resizable: true },
      modalMode: "createUserModal",
      UserModalActive: false,
      inviteCreateUserModalActive: false,
      sortingOrder: null,
      pagination: null,
      paginationPageSize: null,
      uid: null,
    };
  },
  components: {
    AgGridVue,
    createProjectModal,
    editProjectModal,
    deleteProjectModal,
    // inviteCreateUserModal,
    FontAwesomeIcon,
  },
  methods: {
    selectAction(event) {
      // let element = document.getElementById(event.currentTarget.id);
      // element.classList.toggle("active");
      if (event.currentTarget.id !== "createProject" && this.uid == null) {
        return;
      } else if (this.modalMode == event.currentTarget.id + "Modal") {
        this.UserModalActive = !this.UserModalActive;
      } else {
        this.modalMode = event.currentTarget.id + "Modal";
        this.UserModalActive = true;
      }
      // console.log(this.modalMode); // returns 'foo'
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.uid = selectedRows.length === 1 ? selectedRows[0].projectId : "";
      // console.log(this.uid);
    },
    getAllProjects() {
      db.collection("projects")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            //    console.log(doc.id, "=>", doc.data());
            this.rowData.push(doc.data());
            //    console.log(this.rowData);
          });
        });
      // console.log("HAHA");
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    closeUserModal() {
      // let element = document.getElementById(this.modalMode.replace('Modal', ''));
      // element.classList.toggle("active");
      this.rowData = [];
      this.getAllProjects();
      this.UserModalActive = !this.UserModalActive;
    },
  },
  created() {
    this.getAllProjects();
    this.rowSelection = "single";
    this.sortingOrder = ["desc", "asc", null];
    // enables pagination in the grid
    this.pagination = true;
    // sets 10 rows per page (default is 100)
    this.paginationPageSize = 10;
    this.defaultColDef = {
      resizable: true,
    };
  },
  mounted() {
    this.columnDefs = [
      {
        field: "projectName",
        headerName: "Project Name",
        filter: true,
        floatingFilter: true,
        minwidth: 120,
        sort: "asc",
        sortable: true,
      },
      {
        field: "prodCompany",
        headerName: "Production Company",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      {
        field: "enableStreaming",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      // { field: "uid", filter: true, floatingFilter: true },
    ];
    this.rowData = [];
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.projectManagement {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 2;
  width: 100%;
  max-width: 800px;
  background-color: #262830;
  height: 600px;
}

.header {
  background-color: RGB(47, 50, 60);
  height: 70px;
  display: flex;
  align-items: center;
  color: #e5ecff;
  font-size: 1.5em;
  font-weight: 700;
  padding-left: 25px;

  h2 {
    font-size: 24px;
    font-weight: 300;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: auto;
  margin-right: 30px;

  .button {
    position: relative;
    padding: 0px 15px;
    max-height: 37px;
    min-height: 33px;
    font-size: 12px;
    font-weight: 400;
    background-color: RGBA(68, 75, 90, 0.2);
    border: 1px solid #444b5a;
    color: inherit;
    cursor: pointer;
    min-width: 33px;
    margin-left: 10px;
  }

  #deleteProject {
    background-color: RGBA(233, 60, 45, 0.2);
    border: 1px solid RGB(233, 60, 45);
  }

  #deleteProject:hover {
    background-color: RGB(233, 60, 45, 0.8);
  }

  .button:hover {
    background-color: #505874;
  }
  .active {
    background-color: RGBA(239, 243, 255, 0.05) !important;
    border-color: RGB(239, 243, 255) !important;
  }
}

.modalBody {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 15px 15px;
}

.datawrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;

  .datatable {
    width: calc(100% - 30px);
    height: calc(100% - 10px);
    border: 1px solid #000000;
  }
}
</style>
